import { useSiteConfig } from '@elseu/sdu-titan-product-site-components';
import type { AppProps } from 'next/app';
import { CsrfTokenMethod, OidcJwtProvider } from 'oidc-jwt-client';
import { useMemo } from 'react';

import { removeTokenFromUrlFunction } from '@/helpers/removeTokenFromUrlFunction';

interface ClientOidcProviderProps {
  router: AppProps['router'];
  children: React.ReactNode;
}

export function ClientOidcProvider({ children, router }: ClientOidcProviderProps) {
  const { featureFlags, authUrl, isBot } = useSiteConfig();
  const oidcClient = useMemo(
    () =>
      featureFlags.WITH_PUBLIC_SITE
        ? false
        : { url: authUrl, csrfTokenMethod: CsrfTokenMethod.QUERYSTRING },
    [authUrl, featureFlags.WITH_PUBLIC_SITE],
  );

  const shouldAllowLogin = useMemo(
    () => !featureFlags.WITH_PUBLIC_SITE && !isBot && router.pathname !== 'unsupported',
    [featureFlags.WITH_PUBLIC_SITE, isBot, router.pathname],
  );

  return (
    <OidcJwtProvider
      client={oidcClient}
      removeTokenFromUrlFunction={removeTokenFromUrlFunction}
      shouldAttemptLogin={shouldAllowLogin}
      shouldMonitorAccessTokens={shouldAllowLogin}
    >
      {children}
    </OidcJwtProvider>
  );
}
